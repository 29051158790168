
import { defineComponent, reactive, ref, onBeforeUnmount, computed, watch } from 'vue';
import ListWrap from '@/components/list-wrap/Index.vue';
import PackagedPagination from '@/components/pagination/Index.vue';
import { userProjectList } from '@/layout/messageCenter/user-info';
import { getShowBool } from '@/utils/permission-show-module';
import DeleteDialog from './components/DeleteServiceDialog.vue';
import {
  serviceDetail,
  persons,
  tags,
  parseServiceList,
  sorts,
  getTagsForService,
  getClassifications,
  getServiceDependencies,
  dependenciesList,
  ownersMap,
  getServiceVersionType,
  appearanceMap,
} from './utils/service-data-utils';

import { addService, deleteService, getServiceList, serviceNameTest, updateServiceStatus } from '@/api/servers';
import Message from 'element-plus/es/el-message';
import { ElMessage } from 'element-plus';
import { computeStatusLabel, statusColor } from '@/views/service-management/business-service/utils/service-status-map';
import { useAddProxy, SERVICE_APPEARANCE } from './useAddProxy';
import { useSaList } from '@/shared/hooks/list';
import { delSameResourcesFromEnv } from '@/api/project/project';

export default defineComponent({
  name: 'ServiceList',
  components: {
    PackagedPagination,
    ListWrap,
    DeleteDialog,
  },
  data() {
    return {
      sortProps: {
        label: 'name',
        value: 'id',
        emitPath: false,
      },
    };
  },
  setup() {
    const submitting = ref(false);
    const serviceCascaderProps = ref({
      multiple: true,
    } as any);

    // ----------------------------------新增的服务形式
    const { appearance } = useAddProxy();

    const extendQuery = reactive({
      classification: '',
      tags: [],
    });
    const mutiArray = ref([] as any);
    const rememberMutiArray = ref([] as any);
    const serverMuitable = ref(null);

    const refreshTimer = ref();

    const { loading, list, total, query, fetchList, handleSearch, handlePageSizeChange, handlePageChange } = useSaList(
      async (query: any, useLoading = true) => {
        clearTimeout(refreshTimer.value);
        const hasAuth = getShowBool('select');
        if (!hasAuth) {
          list.value = [];
          total.value = 0;
          return;
        }
        if (useLoading) {
          loading.value = true;
        }
        try {
          const {
            data: { rows, count, ownerUsers },
          } = await getServiceList({
            ...query,
            ...extendQuery,
          });
          list.value = parseServiceList(ownerUsers, rows) as any;
          total.value = count;
          rows
            .map((x: any) => {
              if (rememberMutiArray.value.includes(x.id as any)) {
                return x;
              }
              return null;
            })
            .filter((x: any) => x)
            .forEach((x: any) => {
              (serverMuitable.value as any).toggleRowSelection(x, true);
            });
          rememberMutiArray.value = [];
          refreshTimer.value = setTimeout(() => {
            fetchList(false);
          }, 5000);
        } catch (e) {
          console.error('获取服务列表失败', e);
        }
        loading.value = false;
      },
    );

    const initFetch = async () => {
      loading.value = true;
      await Promise.all([getClassifications(), getTagsForService()]).then(async () => {
        loading.value = true;
        await fetchList();
      });
      loading.value = false;
    };

    if (userProjectList.value.length) {
      initFetch();
    }
    const compuMutiArr = ref([] as any);
    const refreshMess = ref(true);

    const addServiceDialog = ref(false);

    const toggleServiceDialog = (type?: number) => {
      if (type !== undefined) {
        appearance.value = type; // 服务形式
      }
      addServiceDialog.value = !addServiceDialog.value;
      getServiceDependencies();
    };

    const labelWidth = ref('100px');
    function clearDialog() {
      const keys = Object.keys(serviceDetail);
      keys.forEach((x) => {
        serviceDetail[x] = '';
      });
    }
    const nameRef = ref(null as any);
    const desRef = ref(null as any);
    function addServiceByForm() {
      nameRef.value.handleBlur();
      desRef.value.handleBlur();
      const senddata = { ...serviceDetail };
      const ownerArr = senddata.owner?.split(',') || '';
      senddata.tag = serviceDetail.tags ? serviceDetail.tags.join(',') : '';
      senddata.dependencies = serviceDetail.dependencies
        ? serviceDetail.dependencies.map((dependency: any) => {
            const [serviceName, serviceVersion] = dependency;
            return {
              serviceName,
              serviceVersion,
              serviceVersionType: getServiceVersionType(serviceName, serviceVersion),
            };
          })
        : [];
      if (!senddata.name) {
        return;
      }

      if (senddata.name.length > 28) {
        return;
      }

      if (ownerArr.length > 10) {
        return ElMessage.warning('负责人最多支持10个');
      }

      const regux = /^[a-z0-9-]+(?<!-)$/;
      if (!regux.test(serviceDetail.name)) {
        return;
      }
      if (senddata.name.length > 60) {
        return ElMessage({
          showClose: true,
          message: '服务名称长度不得超过60个字符',
          type: 'error',
        });
      }
      if (!senddata.description) {
        return;
      }
      if (senddata.description.length > 60) {
        return false;
      }
      submitting.value = true;
      senddata.classification = senddata.classification ? `${senddata.classification}` : '';

      // add service apperance
      senddata.appearance = appearance.value; // 服务形式
      addService(senddata)
        .then(() => {
          fetchList();
          addServiceDialog.value = false;
          submitting.value = false;
          clearDialog();
        })
        .catch(() => {
          addServiceDialog.value = false;
          submitting.value = false;
        });
    }

    function logs(res: any) {
      console.log(res, 'this is logs');
      return res;
    }
    function getCascaderForm(res: any) {
      serviceDetail.classification = res;
    }

    // 筛选
    const blackHoverVisible = ref(false);
    const sortTitleVisiable = ref(false);
    function sortTitleClick() {
      sortTitleVisiable.value = true;
      blackHoverVisible.value = true;
    }
    const tagTitleVisiable = ref(false);
    function tagTitleClick() {
      tagTitleVisiable.value = true;
      blackHoverVisible.value = true;
    }
    const searchData = ref({} as any);

    function handleSelection(res: any) {
      mutiArray.value = res.map((x: any) => x.id);
      compuMutiArr.value = res;
    }

    const computedDisabledForSS = computed(() => {
      let res = false;
      if (compuMutiArr.value.length === 0) {
        return !res;
      }
      compuMutiArr.value.forEach((x: any) => {
        if (+x.status === 20 || +x.status === 10 || +x.status === 21 || +x.status === 30) {
          res = true;
        }
      });
      return res;
    });
    const runDialogVisible = ref(false);
    const stopDialogVisible = ref(false);

    const logData = ref('');

    const logDialogVisible = ref(false);
    const logType = ref('启动服务');

    const runOptions = reactive({} as any);
    const branchOptions = ref(['master', 'dev', 'fix']);

    const submitRunService = () => {
      runDialogVisible.value = false;
      logDialogVisible.value = true;
      logType.value = '启动服务';
    };
    const runService = () => {
      runDialogVisible.value = true;
    };
    const stopService = () => {
      stopDialogVisible.value = true;
    };
    const submitStopService = () => {
      stopDialogVisible.value = false;
      logDialogVisible.value = true;
      logType.value = '停止服务';
    };

    const computedDisabled = computed(() => {
      let res = false;
      if (compuMutiArr.value.length === 0 || compuMutiArr.value.length > 1) {
        return !res;
      }
      compuMutiArr.value.forEach((x: any) => {
        if (+x.status === 20 || +x.status === 10 || +x.status === 0) {
          res = true;
        }
      });
      return res;
    });

    const setOwner = (value: any) => {
      serviceDetail.owner = value.join(',');
    };

    const checkEnglishName = () => {
      if (!serviceDetail.name) {
        return;
      }
      serviceNameTest({ name: `srv-${serviceDetail.name}` });
    };

    function handleOverlayClick() {
      sortTitleVisiable.value = false;
      tagTitleVisiable.value = false;
      blackHoverVisible.value = false;
      fetchList();
    }

    const validatorPass = (rule: any, value: any, callback: any) => {
      const reg = /^(?!-)(?!.*-$)[a-z0-9-]+$/;
      if (!reg.test(value)) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    };
    watch(
      () => list.value,
      (nn) => {
        let ids = [];
        if (nn.length) {
          ids = nn.filter((x: any) => x.status === 30).map((x: any) => x.id);
          if (ids.length) {
            updateServiceStatus(ids);
          }
        }
      },
    );

    onBeforeUnmount(() => {
      clearTimeout(refreshTimer.value);
    });

    const nodeChange = (nodes: any) => {
      const checkNode: any = {};
      for (const node of nodes) {
        checkNode[node[0]] = node;
      }
      const selectData = Object.values(checkNode);
      serviceDetail.dependencies = selectData;
    };

    const deleteServiceDialogRef = ref();

    // 删除服务
    const handleDelete = (row: any) => {
      deleteServiceDialogRef.value.openDialog(`确定删除服务“${row.name}”吗？此操作不可逆。`, async (sync: boolean) => {
        await deleteService([row.id]);
        fetchList();
        Message.success('删除命令下发成功');
        if (sync) {
          delSameResourcesFromEnv({
            serviceType: 'service',
            serviceName: row.name,
            devProjectId: row.projectId,
            env: 'TEST',
          });
        }
      });
    };
    return {
      extendQuery,
      loading,
      list,
      total,
      query,
      fetchList,
      handleSearch,
      handlePageSizeChange,
      handlePageChange,
      serviceDetail,
      persons,
      tags,
      sorts,
      addService,
      addServiceByForm,
      logs,
      addServiceDialog,
      labelWidth,
      toggleServiceDialog,
      sortTitleClick,
      sortTitleVisiable,
      tagTitleClick,
      tagTitleVisiable,
      searchData,
      handleOverlayClick,
      blackHoverVisible,
      serverMuitable,
      handleSelection,
      logDialogVisible,
      logData,
      runDialogVisible,
      runOptions,
      branchOptions,
      submitRunService,
      runService,
      stopService,
      stopDialogVisible,
      submitStopService,
      logType,
      getCascaderForm,
      dependenciesList,
      mutiArray,
      computedDisabled,
      computedDisabledForSS,
      clearDialog,
      computeStatusLabel,
      statusColor,
      ownersMap,
      setOwner,
      refreshMess,
      checkEnglishName,
      userProjectList,
      validatorPass,
      getShowBool,
      nameRef,
      desRef,
      submitting,
      serviceCascaderProps,
      nodeChange,
      appearanceMap,
      SERVICE_APPEARANCE,
      deleteServiceDialogRef,
      handleDelete,
    };
  },
});
